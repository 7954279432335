.nigeria-payments-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.nigeria-payments-container .step {
    
    margin: 20px 20px;
    width: 50%;
}


.nigeria-payments-container li {
  
    list-style-position: outside;
    list-style-type: disc;
    
}


@media screen and (max-width: 1400px) {

    .nigeria-payments-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

    .nigeria-payments-container .step {
        width: 60%;
    }

}


@media screen and (max-width: 600px) {

    .nigeria-payments-container { 
    }

    .nigeria-payments-container .step {
        width: 100%;
    }

}