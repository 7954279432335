.DropdownButton {
  color: var(--white-color);
  border-radius: 5px;
  width: max-content;
}
  
.noselect {
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}

.DropdownElement {
  color: var(--white-color);
  border-radius: 5px;
  padding: 5px;
  width: max-content;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  visibility: visible;
  opacity: 1;
}

.Dont-Display {
  display: none;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
}

.dropdown-button-mobile {
  color: var(--red-color);
}