.tutorial-navbar ul {
    display: flex;
    flex-wrap: wrap;  
}

.tutorial-navbar {
    display: flex;
    justify-content: center;
}

.tutorial-navbar li {
    padding: 10px 20px;
    min-width: 200px;
}

@media screen and (max-width: 900px) {
  
    .tutorial-navbar li {
        display: flex;
        align-items: center;
        padding: 7px 5px;
        min-width: 225px;
        max-width: 225px;
        /* border: 1px solid red; */
    }
}

@media screen and (max-width: 450px) {
    .tutorial-navbar ul {
        font-size: 0.9rem;
        display: flex;
        justify-content: space-around;
        padding: 0px;
    }
    
    .tutorial-navbar li {
        display: flex;
        align-items: center;
        padding: 7px 5px;
        min-width: 150px;
        max-width: 150px;
        /* border: 1px solid red; */
    }
}