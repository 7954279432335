@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* COLOR SCHEME */
:root {
  --primary-color: #04023B;
  --secondary-color: #01CaF3;
  --tertiary-color: #0979ac;
  --black-color: #0E090F;
  --white-color: #FFFFFF;
  --text-color: #4D4D4D;
  --alt-text-color: #FFC32C;
  --navbar-text-color: #CCCCCC;
  --input-text-color: #1A1A1A;
  --green-color: #008654;
  --blue-green-color-opacity-25: rgba(83,234,144,0.25);
  --blue-green-color-opacity-50: rgba(83,234,144,0.50);
  --error-background-color: #FFC32C;
  --error-color: #EA0000;
  --heading-color: #0E090F;
  --bg-color: #CCCCCC;
  --silver-color: #d3d3d3;
  --gold-color: #f4d36c;
  --gold-color-hover: #f5e7ba;
}

body {
  margin: 0;
  font-size: 1.1em;
  background: var(--white-color);
}

ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

.page-title {
  font-size: 1em;
  color: var(--heading-color);
  display: inline-block;
}

.btn-react {
  background: var(--white-color);
  padding: 8px 12px;
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
}

.btn-react:hover {
  color: var(--white-color);
  background-color: var(--primary-color);
}

/* forms */
label {
  display: block;
  margin: 0px 0px 12px 0px;
  width: 80%;
}

label span {
  display: block;
  margin-bottom: 6px;
}

input, textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: var(--input-text-color);
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--input-text-color);
  border-radius: 4px;
}

textarea {
  min-height: 160px;
}

.error {
  color: var(--error-color);
  background: var(--error-background-color);
  border: 1px solid var(--error-color);
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}