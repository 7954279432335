/**
* Template Name: SoftLand - v4.8.0
* Template URL: https://bootstrapmade.com/softland-bootstrap-app-landing-page-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
General
--------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Roboto');
html {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  color: var(--text-color);
  line-height: 1.5;
  overflow-wrap: break-word;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.App {
  display: flex;
}

.app-container{
  padding: 0 0px;
  width: 100%;
}

.container {
  padding: 0px 25px;
  width: 100%;
}


.desktop-hide{
  display: none;
}

.center {
  display: flex;
  justify-content: center;
}

.contact-row {
  display: flex;
  justify-content: center;
}

.tab-content {
  overflow-wrap: break-word;
}



.white {
  color: white;
}

.white:hover {
  color: lightgray;
}

.blue {
  color: blue;
}


a {
  color: var(--primary-color);
  text-decoration: none;
  transition: 0.3s all ease;
  text-decoration: underline;
}

a:hover {
  color: var(--black-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-heading {
  color: var(--black-color);
}

p {
  color: var(--text-color);
}

h2 {
  text-align: center;
  padding-bottom: 10px;
}

h3 {
  text-align: center;
}

.p-mod {
  font-size: 1.2rem;
  
}

.italics {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.text-align-right {
  text-align: right;
  width: 100%;
}

.home-page-h2 {
  padding-top: 30px;
}

.container {
  z-index: 2;
  position: relative;
}

.text-black {
  color: var(--black-color) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.border-top {
  border-top: 1px solid var(--white-color) !important;
}

.border-bottom {
  border-bottom: 1px solid var(--white-color) !important;
}

figure figcaption {
  margin-top: 0.5rem;
  font-style: italic;
  font-size: 0.8rem;
}

section {
  overflow: hidden;
}

.section {
  margin-top: 20px;
  margin-bottom: 60px;
  
}

.cta-section {

  margin-bottom: 0;
  background-color: var(--white-color);
}

.section-heading {
  font-size: 4rem;
  font-weight: 700;
  background: linear-gradient(-45deg, var(--secondary-color), var(--primary-color));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 50px 0px;
}

/*--------------------------------------------------------------
Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 15px;
  bottom: 15px;
  z-index: 996;
  background: var(--secondary-color);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: var(--white-color);
  line-height: 0;
}

.back-to-top:hover {
  background: var(--secondary-color);
  color: var(--white-color);
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/* Default btn styling */
.btn {
  border: none;
  padding: 15px 30px;
}

.btn.btn-outline-white {
  border: 2px solid var(--white-color);
  background: none;
  color: var(--white-color);
}

.btn.btn-outline-white:hover {
  background: var(--white-color);
  color: var(--secondary-color);
}

.btn.btn-primary {
  background: var(--secondary-color);
  background: linear-gradient(-45deg, var(--secondary-color), var(--primary-color));
  color: var(--white-color);
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
}

.feature-1 .wrap-icon {
  margin: 0 auto;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.feature-1 .wrap-icon.icon-1 {
  border: 2px solid var(--black-color);
}

.team-photo {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  object-fit: cover;
  margin-top: 30px;
  margin-bottom: 30px;
}

.team-photo-img {
  width:100%;
  object-fit: cover;
}

.feature-1 .wrap-icon i {
  font-size: 40px;
  line-height: 0;
  color: var(--white-color);
}

.feature-1 h3 {
  font-size: 20px;
}

.feature-1 p {
  color: var(--text-color);
}

.tutorial-step {
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.2);
  background: var(--white-color);
  padding: 40px;
  width: 100%;  
}


/* Accepted Payment Methods Section Desktop */

.step {
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.2);
  background: var(--white-color);
  padding: 40px;
  width: 100%;
  height: 350px;  
}

.step .number {
  background: linear-gradient(-45deg, var(--secondary-color), var(--primary-color));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  letter-spacing: 0.2rem;
  margin-bottom: 10px;
  display: block;
}

.step p:last-child {
  margin-left: 20px;
}

.step li {
  margin-bottom: 0px;
  margin-left: 20px;
  font-size: 1.2rem;
}

.step h3 {
  margin-bottom: 20px;
}

.step .google-badge-container{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: 0px;
}

.step .apple-badge-container{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: 0px;
}

.review h3 {
  font-size: 20px;
}

.review p {
  line-height: 1.8;
  font-style: italic;
  color: var(--primary-color);
}

.review .stars span {
  color: var(--error-color);
}

.review .stars .muted {
  color: var(--text-color);
}

.review .review-user img {
  width: 70px;
  margin: 0 auto;
}

/* Testimonial Carousel */
.testimonials-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: var(--white-color);
  opacity: 1;
  border: 1px solid var(--secondary-color);
}

.testimonials-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--secondary-color);
}

/* Newsfeed Carousel */

.newsfeed .newsfeed-photo-container img {
  width: 100%;
  /* box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.8);  */
}

.newsfeed .newsfeed-photo-container img:hover {
  transform: scale(1.03);
  opacity: 0.7;
  transition: 0.3s;
}

.newsfeed h3 {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 2rem;
}

.newsfeed h3:hover {
  opacity: 0.7;
  text-decoration: underline;
}

.newsfeed a {
  text-decoration: none;
  font-size: 1rem;
}

.newsfeed-container {
  width: 100%;
}

.newsfeed {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsfeed-swiper-slide {
  width: 100%;
  padding: 0px 80px;
  margin: 0px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

/* Newsfeed Step */
.newsfeed-step {
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.2);
  background: var(--white-color);
  padding: 40px 40px;
}

.temp-newsfeed-h3 {
  padding-top: 20px;
}

.newsfeed-swiper-wrapper {
  padding-left: 0vw;
  /* padding-left: 9.0vw; ***REUSE THIS PADDING WHEN JS SWIPER WORKING AGAIN*/
}

@media screen and (max-width: 2000px) {
  .newsfeed-swiper-wrapper {
    padding-left: 0vw;
    /* padding-left: 9.5vw; ***REUSE THIS PADDING WHEN JS SWIPER WORKING AGAIN*/
  }
}

@media screen and (max-width: 1800px) {
  .newsfeed-swiper-wrapper {
    padding-left: 0vw;
    /* padding-left: 10.0vw; ***REUSE THIS PADDING WHEN JS SWIPER WORKING AGAIN*/
  }
}

@media screen and (max-width: 1600px) {
  .newsfeed-swiper-wrapper {
    padding-left: 0vw;
    /* padding-left: 10.5vw; ***REUSE THIS PADDING WHEN JS SWIPER WORKING AGAIN*/
  }
}

@media screen and (max-width: 1400px) {
  .newsfeed-swiper-wrapper {
    padding-left: 0vw;
    /* padding-left: 11.0vw; ***REUSE THIS PADDING WHEN JS SWIPER WORKING AGAIN*/
  }
}

@media screen and (max-width: 1200px) {
  .newsfeed-swiper-wrapper {
    padding-left: 00vw;
    /* padding-left: 11.5vw; ***REUSE THIS PADDING WHEN JS SWIPER WORKING AGAIN*/
  }
}

@media screen and (max-width: 1000px) {
  .newsfeed-swiper-wrapper {
    padding-left: 0vw;
    /* padding-left: 12.0vw; ***REUSE THIS PADDING WHEN JS SWIPER WORKING AGAIN*/
  }
}

.pricing {
  padding: 50px;
  background: linear-gradient(-45deg, var(--secondary-color), var(--primary-color));
  color: var(--white-color);
  padding-bottom: 1rem !important;
  position: relative;
  border-radius: 4px;
  color: var(--black-color);
  background: var(--white-color);
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
}

.pricing h3 {
  color: var(--black-color);
}

.feature-descriptions h3 {
  text-align: left;
}

.price-title.features {
margin-top: 30px;
}

.pricing .price-cta {
  text-decoration: none;
  position: absolute;
  bottom: 50px;
  width: 100%;
  text-align: center;
  left: 0;
}

.only-monthly {
  margin-bottom: 45px;
}

.price-cta .subtext {
  margin-top: -10px;
  color: var(--white-color);
}

.pricing .price-cta .price {
  display: block;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 300;
}

.pricing .popularity {
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  display: block;
  margin-bottom: 20px;
  height: 55px;
  color: black;
}



.pricing ul {
  margin-bottom: 50px;
}

.pricing ul li {
  margin-bottom: 10px;
}

.pricing .btn-white {
  background: var(--white-color);
  border: 2px solid var(--navbar-text-color);
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
}

.pricing .btn-white:hover {
  color: var(--secondary-color);
}

.pricing .btn-blue {
  background: var(--white-color);
  border: 2px solid var(--navbar-text-color);
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
}

.pricing .btn-blue:hover {
  color: var(--white-color);
}

.underline {
  text-decoration: underline;
}

.pricing.popular {
  background: var(--white-color);
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  color: var(--black-color);
  background: linear-gradient(-45deg, var(--secondary-color), var(--primary-color));
  color: var(--white-color);
  box-shadow: none;
}



.pricing.popular.silver {
  opacity: 0.95;
 
}

.gold {
  color: var(--gold-color);
}

.pricing.popular.gold {
  border: 5px solid var(--gold-color);
}

.pricing.popular.silver {
  border: 5px solid var(--white-color);
}

.pricing.popular.family {
  border: 5px solid var(--white-color);
}

.pricing.popular.family {
  opacity: 0.95;
  
}

.pricing.popular .popularity {
  color: var(--white-color);
  font-weight: 600;
}

.pricing .popularity.outlined {
  border: 2px solid var(--white-color);
  height: 65px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing .popularity.no-outline {
  height: 65px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing.popular h3 {
  color: var(--white-color);
  background: none;
}

.pricing.popular h5 {
  color: var(--white-color);
}

.pricing.popular .btn-white {
  border: 2px solid var(--primary-color);
}

/* NEW PRICING SECTION - ON DESKTOP */ 

.desktop-hidden {
  display: none;
}

.li-features {
  font-size: 1rem;
  padding: 5px 0px;
  height: 60px;
}

.pricing-container {
  display: flex;
  justify-content: center;
  flex-direction: rows;
}

.row > .pricing-columns {
  padding-right: 5px;
  padding-left: 5px;
}

.pricing-columns h3 {
  font-size: 1.5rem;
}

.pricing.popular h3 {
  color: var(--gold-color);
}

.pricing.silver h3 {
  color: var(--silver-color);
}

/* .price-cta.price-white h3 {
  color: var(--white-color);
} */

.price-title {
  height: 60px;
  
}

.material-icon.sizing {
  font-size: 30px;
}



.blue-check {
color: var(--black-color);
font-weight: 900;
font-size: 3rem;
height: 60px;
}

.silver-check {
  color: var(--silver-color);
  font-weight: 900;
  font-size: 2.5rem;
  /* height: 60px; */
}

  .gold-check {
    color: var(--gold-color);
    font-weight: 900;
    font-size: 2.5rem;
    /* height: 60px; */
  }

.white-check {
  color: var(--white-color);
  font-weight: 900;
  font-size: 3rem;
  height: 60px;
  }

/* CTA Section */
.cta-section {
  background: linear-gradient(to right, var(--primary-color) 0%, var(--secondary-color) 100%);
  color: var(--white-color);
}

.cta-section h2 {
  color: var(--white-color);
  font-size: 3rem;
  font-weight: 700;
}

.cta-section h3 {
  color: var(--white-color);
  font-size: 2rem;
  font-weight: 700;
}

.graphic-icon {
  height: 60px;
}

/* Input Fields */ 
#user-login {
  width: 10vw;
  padding-left: 0px;
  height: 50px;
  padding: 10px 30px 10px 30px !important;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.mission-box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mission-text {
  width: 85%;
}

.mission-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: 0px;
}

.feature-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
 padding: 0px;
}

.col-md-4 {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.col-md-6 {
display: flex;
flex-direction: column;
align-items: center;
}

/* Home Page Safety Section */

.home-safety-container {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.home-safety {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  
}

.home-safety-box {
  width: 50%;
  margin-bottom: 20px;
  
}

.home-safety-box-inner {
  width: 60%;
  
}

.safety-section img {
 margin-top: 30px;
}

/* Privacy Policy Section */

.translations-content-container h1 {
  text-align: left;
  margin: 20px 0px;
}

.translations-content-container h2 {
  text-align: left;
  margin: 20px 0px;
}

.translations-content-container h3 {
  text-align: left;
  margin: 20px 0px;
}

.translations-content-container li {
  list-style-type: disc;
}

/* Footer CTA Section */

.cta-section {
  
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}
.cta-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.cta-text {
  max-width: 500px;
  margin: 0px 20px;
 }

.cta-section h3 {
  color: var(--white-color);
  font-size: 2rem;
  font-weight: 700;
}

.qr-code {
  max-height: 175px;
  max-width: 175px;
  margin: 0px 20px;
}

.google-badge-container {
  margin: 10px 20px;
  width: 50%;
}

.apple-badge-container {
  margin: 10px 20px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.badges-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
 
 
}

.text-lg-start{
  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.badges-container-flex {
  display: flex;
  flex-direction: row;
  
}

.google-play-badge {
  height: 85px;
}

.appple-app-badge {
  height: 60px;
 
}

.home-images{
  width: 35%;
}

.home-power {
  width: 35%;
}

.home-power-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}



.third-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-power p {
  margin-bottom: 10px;
}

.home-power-top h3 {
  padding-top: 40px;
}

.about-h2 {
  margin-bottom: 20px;
}

.about-h3 {
  margin-top: 30px;
}

.me-auto {
  margin: 0px !important;
}

.ms-auto {
  margin: 0px !important;
}

.home-section {
  display: flex ;
  justify-content: space-around !important;
}

.source {
  color: var(--alt-text-color);
  font-size: 0.85rem;
}

.safety-section {
  margin-bottom: 50px;
}

.tutorial-flex {
  display: flex;
  justify-content: center;
}

.tutorial-top-container {
  width: 60%;
}

.tutorial-container {
  width: 100%;
  margin-bottom: 10px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  margin-bottom: 10px !important;
}

.tutorial-container h2 {
  margin-bottom: 50px;
}
.tutorial-content {
  width: 100%;
}

.tutorial-text-box {
  width: 100%;
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
}

.tutorial-text-box p {
  text-align: left;
}

.tutorial-nav {
  list-style-type: none;
  padding: 0px 24px;
}


.tutorial-nav-container {
  width: 100%;
}

.tutorial-nav-box {
  padding: 100px 0px;
}

.tutorial-nav a {
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
}

.tutorial-nav h2 {
  line-height: 1.5rem;
  text-align: left;
  margin: 32px 0px;
}

/* Pricing Page Formating */ 

.ppricing-h2 {
  margin-top: 50px;
  margin-bottom: 0x;
}

/* Contact Us / Jobs Page Formating */ 

.contact-form-container {
  padding: 50px 0px 0px 0px;
  width: 100%;
}

.contact-form-box p{
text-align: center;
}

.contact-form-box {
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}



.hubspot-form {
  width: 50%;
}
  
.contact-google-form {
  width: 500px;
  height: 700px;
}

.jobs-google-form {
  width: 100%;
  height: 2100px;
}

.beta-tester-google-form {
  width: 100%;
  height: 350px;
}

.img-fluid {
  padding-bottom: 25px;
}

.img-home{
  width: 70%;
}

.tutorial-img {
  width: 300px;
  margin: 20px;
}

.tutorial-img-container {
  width: 100%;
  /* background: var(--white-color); */
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

.tutorial-img-container-multiple {
  display: flex;
  flex-wrap: nowrap;
}

.tutorial-img-multiple {
  width: 49%;
  margin: 20px 2px;
}

.mission-text h2 {
  text-align: center;
}

/* FAQ Page Accordian Panels */

/* Aligns the heading text to the center. */
 
/* Sets the width for the accordion. Sets the margin to 90px on the top and bottom and auto to the left and right */

.faq-accordion {
  width: 100%;
  margin: 0px auto;
  color: var(--black-color);
  background-color: var(--white-color);
  padding: 0px 45px;
}

.faq-accordion .faq-sub-box {
  position: relative;
  margin: 20px 10px;
  border-bottom: 2px solid var(--primary-color);
}

/* Positions the labels relative to the .container. Adds padding to the top and bottom and increases font size. Also makes its cursor a pointer */

.faq-accordion .faq-label {
  position: relative;
  padding: 0px 0;
  font-size: 1.5rem;
  color: black;
  cursor: pointer;
  text-align: left;
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
}

.faq-label h3 {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: left;
}

.faq-accordion .faq-content {
  margin: 0px;
  position: relative;
  background: var(--white-color);
  height: 0px;
  font-size: 1.2rem;
  text-align: left;
  width: 100%;
  overflow: hidden;
  transition: 0.3s;
}

/* Adds a horizontal line between the contents */

.faq-accordion hr {
  width: 100;
  margin-left: 0;
  border: 1px solid var(--text-color);
}

.faq-sub-box.active .faq-content.ans-short {
  height: 30px
}

.faq-sub-box.active .faq-content.ans-med {
  height: 60px
}

.faq-sub-box.active .faq-content.ans-long {
  height: 90px
}

.faq-sub-box.active .faq-content.ans-vlong {
  height: 90px
}

.faq-h2 {
  margin-bottom: 16px;
}

/* MEDIA QUERIES - DEVICE SIZES */

@media screen and (max-width: 768px) {
  .cta-section h2 {
    font-size: 2rem;
  }
}

.cta-section .btn {
  background: var(--black-color);
  color: var(--white-color);
}

.cta-section .btn i {
  margin-right: 5px;
  font-size: 24px;
  line-height: 0;
}

/* Contact Form */
.form-control {
  height: 48px;
  border-radius: 0;
  border: 1px solid var(--navbar-text-color);
}

.form-control:active,
.form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid var(--secondary-color);
}

.php-email-form .validate {
  display: none;
  color: var(--error-color);
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.php-email-form .error-message {
  display: none;
  color: var(--white-color);
  background: var(--error-color);
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.php-email-form .error-message br+br {
  margin-top: 25px;
}

.php-email-form .sent-message {
  display: none;
  color: var(--white-color);
  background: var(--green-color);
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.php-email-form .loading {
  display: none;
  background: var(--white-color);
  text-align: center;
  padding: 15px;
}

.php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--green-color)e;
  border-top-color: var(--white-color);
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.php-email-form textarea {
  min-height: 160px;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Blog */
.post-entry {
  margin-bottom: 60px;
}

.post-entry .post-text .post-meta {
  text-decoration: none;
  color: var(--navbar-text-color);
  font-size: 13px;
  display: block;
  margin-bottom: 10px;
}

.post-entry .post-text h3 {
  font-size: 20px;
  color: var(--black-color);
}

.post-entry .post-text h3 a {
  text-decoration: none;
  color: var(--black-color);
}

.post-entry .post-text h3 a:hover {
  text-decoration: none;
  color: var(--secondary-color);
}

.post-entry .post-text .readmore {
  color: var(--secondary-color);
}

.sidebar-box {
  margin-bottom: 30px;
  padding: 25px;
  font-size: 15px;
  width: 100%;
  float: left;
  background: var(--white-color);
}

.sidebar-box *:last-child {
  margin-bottom: 0;
}

.sidebar-box h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.categories li,
.sidelink li {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dotted gray("300");
  list-style: none;
}

.categories li:last-child,
.sidelink li:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}

.categories li a,
.sidelink li a {
  text-decoration: none;
  display: block;
}

.categories li a span,
.sidelink li a span {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--navbar-text-color);
}

.categories li.active a,
.sidelink li.active a {
  text-decoration: none;
  color: var(--black-color);
  font-style: italic;
}

.comment-form-wrap {
  clear: both;
}

.comment-list {
  padding: 0;
  margin: 0;
}

.comment-list .children {
  padding: 50px 0 0 40px;
  margin: 0;
  float: left;
  width: 100%;
}

.comment-list li {
  padding: 0;
  margin: 0 0 30px 0;
  float: left;
  width: 100%;
  clear: both;
  list-style: none;
}

.comment-list li .vcard {
  width: 80px;
  float: left;
}

.comment-list li .vcard img {
  width: 50px;
  border-radius: 50%;
}

.comment-list li .comment-body {
  float: right;
  width: calc(100% - 80px);
}

.comment-list li .comment-body h3 {
  font-size: 20px;
}

.comment-list li .comment-body .meta {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.1em;
  color: var(--navbar-text-color);
}

.comment-list li .comment-body .reply {
  padding: 5px 10px;
  background: var(--navbar-text-color);
  color: var(--black-color);
  text-transform: uppercase;
  font-size: 14px;
}

.comment-list li .comment-body .reply:hover {
  color: var(--black-color);
  background: var(--navbar-text-color);
}

.search-form {
  background: var(--navbar-text-color);
  padding: 10px;
}

.search-form .form-group {
  position: relative;
}

.search-form .form-group input {
  padding-right: 50px;
}

.search-form .icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

/*--------------------------------------------------------------
Header
--------------------------------------------------------------*/
#header {
  height: 90px;
  transition: all 0.3s;
  z-index: 997;
  background: var(--primary-color);
}

#header.expanded {
  height: 260px;
  transition: all 0.3s;
  z-index: 997;
  background: var(--primary-color);
}

#header.header-scrolled {
  background: var(--primary-color);
}

#header .logo h1 {
  font-size: 28px;
  margin: 0;
  padding: 4px 0;
  line-height: 1;
  font-weight: 500;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: var(--white-color);
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 60px;
}

#nav-display-flex {
  width: 100%;
}

/*--------------------------------------------------------------
Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: start;
  justify-content: start;
  font-size: 16px;
  font-weight: 600;
  color: var(--white-color);
  white-space: nowrap;
  transition: 0.3s;
  text-decoration: none;
}

.navbar .btn-react {
  font-size: 16px;
  font-weight: 600;
}

.login-button {
  margin: 0px 10px;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: var(--white-color);
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: var(--white-color);
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  color: var(--primary-color);
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: var(--secondary-color);
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: var(--white-color);
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
  padding-right: 20px;
  margin-top: -5px;
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(27, 49, 94, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 20px;
  left: 0px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 75px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: var(--white-color);
  overflow-y: auto;
  transition: 0.3s;
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
}

.navbar-mobile li {
  padding: 5px 0px;
  margin-top: 0px;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.navbar-mobile .btn-react {
  font-size: 18px;
  font-weight: 600;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: var(--secondary-color);
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: var(--white-color);
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: var(--secondary-color);
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
Hero Section
--------------------------------------------------------------*/

.hero-section {
 /* background: linear-gradient(to right, rgba(39, 70, 133, 0.8) 0%, rgba(61, 179, 197, 0.8) 100%), url(../img/hero-bg.jpg); */
 /*  background: linear-gradient(to bottom, rgba(94, 75, 255, 0.9) 0%, rgba(176, 142, 255, 0.9) 100%), url(../img/softland-template/hero-bg.jpg); */
  background: var(--primary-color);
  background: linear-gradient(180deg, var(--primary-color) 0%, var(--primary-color) 40%, var(--secondary-color) 100%);
  position: relative;
  padding-top: 50px;
}

.hero-section .wave {
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  bottom: -200px;
}

@media screen and (max-width: 1400px) {
  .hero-section .wave {
    bottom: -180px;
  }
}

.hero-section .wave svg {
  width: 100%;
}

.hero-section,
.hero-section>.container>.row {
  min-height: 400px; 
}

.hero-section-homepage,
.hero-section-homepage>.container>.row {
  min-height: 800px; 
}

.hero-section.inner-page {
  height: 60vh;
  min-height: 0;
}

.hero-section.inner-page .hero-text {
  transform: translateY(-150px);
  margin-top: -120px;
}

@media screen and (max-width: 1400px) {
  .hero-section.inner-page .hero-text {
    margin-top: -80px;
  }
}

.hero-section h1 {
  font-size: 3.5rem;
  color: var(--white-color);
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .hero-section h1 {
    font-size: 2.2rem;
    text-align: center;
    margin-top: 40px;
  }
}

@media screen and (max-width: 1400px) {
  .hero-section .hero-text-image {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 600px) {
  .hero-section .hero-text-image {
    margin-top: 0rem;
  }
}

.hero-section p {
  font-size: 18px;
  color: var(--white-color);
}

.hero-section .iphone-wrap {
  position: relative;
}

@media screen and (max-width: 1400px) {
  .hero-section .iphone-wrap {
    text-align: center;
  }
}

.hero-section .iphone-wrap .phone-2,
.hero-section .iphone-wrap .phone-1 {
  position: absolute;
  top: -6%;
  overflow: hidden;
  left: 0;
  border-radius: 30px;
}

.hero-section .iphone-wrap .phone-2,
.hero-section .iphone-wrap .phone-1 {
  width: 250px;
}

.hero-section .iphone-wrap .phone-2 {
  margin-top: 50px;
  margin-left: 150px;
  width: 250px;
}

/*--------------------------------------------------------------
Footer
--------------------------------------------------------------*/
.footer {
  padding: 5rem 0 2.5rem 0;
  background-color: var(--white-color);
}

.footer h3 {
  font-size: 18px;
  margin-bottom: 30px;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer a {
  color: var(--black-color);
  text-align: right;
}

.footer-navigation {
  display: flex;
  align-items: flex-start;
}

.footer .copyright {
  margin-bottom: 0px;
}

.footer .copyright,
.footer .credits {
  font-size: 14px;
}

.social a {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--white-color);
  position: relative;
  text-align: center;
  transition: 0.3s background ease;
  
  line-height: 0;
  color: var(--tertiary-color);;
    font-size: 1.6rem;
}

.social a span {
  display: inline-block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.social a:hover {
  color: var(--white-color);
  background: var(--tertiary-color);
}

.social a i {
  line-height: 0;
}

/* Parallax Section */
.banner1 {
	background-image: url(assets/img/lagos-night-crop-compress.webp);
	height: 600px;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 3;
	display: block;
	margin: auto;
  background-attachment: scroll;
  background-size: fill;
}

.banner2 {
	background-image: url(assets/img/deco-command-center-4-crop-4-compress.webp);
	height: 600px;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 3;
	display: block;
	margin: auto;
  background-attachment: scroll;
  background-size: fill;
}

.banner3 {
	background-image: url(assets/img/deco-office-6-compress.webp);
	height: 600px;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 3;
	display: block;
	margin: auto;
  background-attachment: scroll;
  background-size: fill;
}

.banner-american-1 {
	background-image: url(assets/img/tethered-american-travel-banner.png);
	height: 500px;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 3;
	display: block;
	margin: auto;
  background-attachment: scroll;
  background-size: fill;
}

.banner-american-2 {
	background-image: url(assets/img/unsplash-american-flag.jpg);
	height: 600px;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 3;
	display: block;
	margin: auto;
  background-attachment: scroll;
  background-size: fill;
  margin-bottom: -20px;
}


.banner-mexico-1 {
	background-image: url(assets/img/tethered-mexico-banner.png);
	height: 500px;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 3;
	display: block;
	margin: auto;
  background-attachment: scroll;
  background-size: fill;
}


.banner-latam-1 {
	background-image: url(assets/img/tethered-latam-banner.png);
	height: 500px;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 3;
	display: block;
	margin: auto;
  background-attachment: scroll;
  background-size: fill;
}


/* Number Counter Animaiton on Desktop */ 

.home-stats-container {
  width: 30%;
  margin: 0px 0px;
  padding: 0px 30px;
  
}


.home-stats-hide {
  display: none;
}

.numbers {
  font-size: 1.7rem;
  line-height: 1em;
  text-align: center;
  margin: 40px auto;
  overflow: hidden;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}

.numbers__window {
  display: inline-block;
  overflow: hidden;
  width: 1.1em;
  height: 1em;
  color: var(--black-color);
  font-weight: 500;
  margin: 0px 0px;
  padding-right: 10px;
  margin-right: -15px; 
}

#visibilityFeedback {
  color: var(--error-color);
}

.word__window {
  width: 8em;
  height: auto;
  padding-bottom: 10px;
}

.numbers__window__digit {
  font: inherit;
  word-break: break-all;
  display: block;
  width: 0;
  padding: 0 25px 0 0;
  margin: 0 auto;
  overflow: inherit;
  animation: counting 0.4s steps(10) forwards infinite;
}

.numbers__window__digit::before {
  content: attr(data-fake);
  display: inline-block;
  width: 100%;
  height: auto;
}

@keyframes counting {
  100% {
    transform: translate3d(0, -10em, 0);
  }
}

.numbers__window__digit--1 {
  animation-iteration-count: 2;
}
.numbers__window__digit--2 {
  animation-iteration-count: 3;
}
.numbers__window__digit--3 {
  animation-iteration-count: 4;
}
.numbers__window__digit--4 {
  animation-iteration-count: 5;
}
.numbers__window__digit--5 {
  animation-iteration-count: 6;
}



/* TABLET SECTION */ 

@media screen and (max-width: 1400px) {

  .tablet-hide {
    display: none;
    
  }

.section {
  padding: 20px 10px;
}

.container {
  padding: 0px 0px;
  width: 100%;
}

.hubspot-form {
  width: 100%;
}


.home-section .col-md-4 {
  width: 100%; 
}

/* Header Nav Scroll Height on Tablet */

#header {
  height: auto;
}

/* Reverse logo and hamburger menu on tablet: */
.navbar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.mobile-nav-toggle {
  display: block;
}

.navbar li {
  display: none;
}

.navbar-mobile li {
  margin: 5px;
}

.navbar-mobile .big-login-button {
  margin: 15px;
}

/* H2 Section Heading on Tablet */ 

.section-heading {
  font-size: 3rem;
}
/* FAQ Accordion Menu on Tablet  */

.faq-sub-box.active .faq-content.ans-short {
  height: 70px
}

.faq-sub-box.active .faq-content.ans-med {
  height: 120px
}

.faq-sub-box.active .faq-content.ans-long {
  height: 150px
}

.faq-sub-box.active .faq-content.ans-vlong {
  height: 210px
}
  
.faq-accordion {
  padding: 0px 0px;
}

.cta-section .btn {
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  margin-right: 5px;
  font-size: 16px;
  line-height: 1.0;
  text-align: center;
}

/* Improve Your Safety Section on Home Page on Tablet  */

.home-safety {
  width: 100%;
}

.home-safety-box {
  width: 100%;
}

/* Take Back The Power Section on Home Page on Tablet  */

.mobile-off {
  display: none;
}

.home-images{
  width: 60%;
}

.home-power {
  width: 100%;
}

/* define hero section height on Tablet */

.hero-section,
.hero-section>.container>.row {
  min-height: 300px; 
  padding-top: 30px;
}

.hero-section-homepage,
.hero-section-homepage>.container>.row {
  min-height: 500px; 
}

/* Phone Images on Home Page on Tablet */
.hero-section .iphone-wrap .phone-2,
.hero-section .iphone-wrap .phone-1 {
  display: none;
  /* position: relative;
  top: 0;
  max-width: 100%; */
}

.hero-section .iphone-wrap .phone-1 {
  display: none;
  /* margin-left: -100px; */
}

.hero-section .iphone-wrap .phone-2 {
  display: none;
  /* width: 250px;
  position: absolute;
  margin-top: 0px;
  margin-left: 300px; */
}

/* Contact Us / Jobs Page Google Form - Tablet */

.contact-google-form {
  height: 700px;
}

.jobs-google-form {
  width: 100%;
  height: 2150px;
}

.beta-tester-google-form {
  width: 100%;
  height: 400px;
}

/* Footer Navigation on Tablet */

.footer .col-md-4 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer .col-md-4.footer-downloads {
  width: 100%;
}

.footer h3 {
  text-align: left;
}

/* Banner Parallax Images on Tablet: */

.banner1 {
  background-attachment: scroll;
  background-size: fill;
  max-height: 400px;
}

.banner2 {
  background-attachment: scroll;
  background-size: fill;
  max-height: 400px;
}

.banner3 {
  background-attachment: scroll;
  background-size: fill;
  max-height: 400px;
}

/* Newsfeed Slider on Tablet */
.newsfeed-swiper-slide {
  width: 100%;
}

.newsfeed-swiper-wrapper {
  padding-left: 0vw;
}

/* Home Stats Section on Tablet */

.home-power-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.home-stats-container {
  width: 100%;
}

/* pricing section on tablet */

/* NEW PRICING SECTION - ON TABLET */ 

.desktop-hidden {
  display: contents;
}

.pricing {
  padding: 0px 10px;
  padding-bottom: 10rem !important;
  padding-top: 30px;
}

.only-monthly {
  margin-bottom: 30px;
}

.pricing-container {
  display: flex;
  flex-direction: rows;
  width: 105%;
}

.row > .pricing-columns {
  padding-right: 5px;
  padding-left: 5px;
  width: 25%;
}

.pricing .btn-white {
  font-size: 0.7rem;
}

.pricing .btn-blue {
  font-size: 0.7rem;
}

.pricing-columns h3 {
  font-size: 0.8rem;
}

.material-icon.sizing {
  font-size: 20px;
}

.pricing.popular.gold {
  border: 3px solid var(--gold-color);
}

.pricing.popular.silver {
  border: 3px solid var(--white-color);
}

.pricing.popular.family {
  border: 3px solid var(--white-color);
}

.pricing .popularity {
  font-size: 0.6rem;
  letter-spacing: 0.2rem;
  display: block;
  /* padding-top: 20px; */
  margin-bottom: 20px;
  height: 55px;
}

.pricing .popularity.outlined {
  height: 45px;
}

.pricing .popularity.no-outline {
  height: 45px;
}

.li-features {
  font-size: 0.6rem;
  padding: 5px 0px;
  height: 40px;
}

.price-title {
  height: 40px;
}



.pricing .price-cta .price {
  margin-bottom: 20px;
  font-size: 1.0rem;
  font-weight: 300;
}

.price-cta .subtext {
  font-size: 0.7rem;
}

.btn {
  --bs-btn-font-size: 0.7rem;
  padding: 5px 10px;
}

.blue-check {
  color: var(--seconary-color);
  font-weight: 900;
  font-size: 1.5rem;
  height: 40px;
  padding: 5px 0px;
}

.white-check {
  color: var(--white-color);
  font-weight: 900;
  font-size: 1.5rem;
  height: 40px;
  padding: 5px 0px;
  }

.silver-check {
  color: var(--silver-color);
  font-weight: 900;
  font-size: 1.5rem;
  /* height: 40px; */
  padding: 5px 0px;
  }

  .gold-check {
    color: var(--gold-color);
    font-weight: 900;
    font-size: 1.5rem;
    height: 40px;
    padding: 5px 0px;
  }

.footer .col-md-4.footer-nav-about {
  width: 40%;
}

/* CTA Section on Tablet */

.cta-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.cta-section {
  padding-top: 10px;
}
  
.cta-section .btn {
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
}

.cta-text {
  max-width: 800px;
  margin: 20px 20px;
}


.qr-code {
  display: flex;
  max-height: 150px;
  max-width: 150px;
  margin: 0px 20px;
}

.google-badge-container {
  margin: 20px 20px;
  width: 95%;
}

/* .badges-container-flex-tablet {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
} */
  
.apple-badge-container {
  margin: 20px 20px;
}

.google-play-badge {
  height: 75px;
}

.appple-app-badge {
  height: 55px;
}

.tutorial-top-container {
  width: 90%;
}

.tutorial-container {
  width: 100%;
}


/* Accepted Payment Methods TABLET */

.step {
 
 
 
}

.step .number {
 
}

.step p:last-child {
  
}

.step li {
 
}

.step h3 {
  
}

.step .google-badge-container{
  
}

.step .apple-badge-container{
 
}

  }

@media screen and (max-width: 768px) {

/* Phone Images on Home Page on Tablet Small */

.hero-section .iphone-wrap .phone-2 {
  width: 250px;
  position: absolute;
  margin-top: 0px;
  margin-left: 200px;
}

.footer .col-md-4.footer-nav-about {
  width: 100%;
}
  
}

/* MOBILE SECTION */ 

@media screen and (max-width: 600px) {

/* FAQ Accordion Menu on Mobile  */

.mobile-hide{
  display: none;
}


.section {
  padding: 10px 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.container {
  padding: 0px 10px;
}

.cta-container {
  width: 100%;
}

.cta-text {
  max-width: 500px;
  min-width: 100px;
  margin: 20px 20px;
}

.cta-section h3 {
  color: var(--white-color);
  font-size: 1.5rem;
  font-weight: 700;
}

.qr-code {
  max-height: 120px;
  max-width: 120px;
  margin: 0px 20px;
}

.badges-container {
  width: 100%; 
}

.google-badge-container {
  margin: 20px 10px;
  width: 50%;
}
  
.apple-badge-container {
  margin: 20px 10px;
  width: 40%;
}

/* Footer CTA Section on Mobile */

.badges-container-flex-tablet {
  display: flex;
  flex-direction: row;
}

.google-play-badge {
  max-height: 65px;
}

.appple-app-badge {
  max-height: 48px;
}

/* Header Nav Scroll Height on Mobile */

#header {
  height: auto;
}

/* H2 Section Heading on Mobile */ 

.section-heading {
  font-size: 2.2rem;
}

.section.faq {
  height: 150px;
  padding-bottom: 0px;
}

.faq-accordion {
  padding: 0px 10px;
}

/* FAQ Section Sizing on Mobile */ 

.faq-sub-box.active .faq-content.ans-short {
  height: 130px
}

.faq-sub-box.active .faq-content.ans-med {
  height: 210px
}

.faq-sub-box.active .faq-content.ans-long {
  height: 350px
}

.faq-sub-box.active .faq-content.ans-vlong {
  height: 460px
}

.col-md-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.col-md-6 {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

/* Stats Section On Homepage On Mobile */

.numbers {
  margin-top: 0px;
}

.img-home{
  padding-bottom: 50px;
}

/* Take Back The Power Section on Home Page on Mobile */

.mobile-off {
  display: none;
}

.home-images{
  width: 75%;
}

/* define hero section height on mobile */

.hero-section,
.hero-section>.container>.row {
  min-height: 100px; 
  padding-top: 30px;
}

.hero-section-homepage,
.hero-section-homepage>.container>.row {
  min-height: 400px; 
}

/* Phone Images on Home Page on Mobile */

.hero-section .iphone-wrap .phone-1 {
  display:none;
  /* margin-left: -80px; */
}

.hero-section .iphone-wrap .phone-2 {
  display:none;
  /* width: 250px;
  position: absolute;
  left: 50vw;
  margin-top: 0px;
  margin-left: -100px; */
}

/* Footer Navigation on Mobile */

.footer .col-md-4.footer-nav-about {
  width: 100%;
}
  
.footer .col-md-4 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
  
.footer .col-md-4.footer-downloads {
  width: 100%;
}
  
.footer h3 {
  text-align: left;
}
  
/* Contact Us / Jobs Page Google Form - Mobile */

.contact-google-form {
  width: 100%;
  height: 700px;
}

.jobs-google-form {
  width: 100%;
  height: 2350px;
}

.beta-tester-google-form {
  width: 100%;
  height: 500px;
}

/* Banner Parallax Images on Mobile: */

.banner1 {
  background-attachment: scroll;
  background-size: fill;
  max-height: 300px;
}

.banner2 {
  background-attachment: scroll;
  background-size: fill;
  max-height: 300px;
}

.banner3 {
  background-attachment: scroll;
  background-size: fill;
  max-height: 300px;
}

/* Newsfeed Slider on Mobile */

.newsfeed-container { 
  padding: 0px;
  margin: 0px;
}

.newsfeed-swiper-slide {
  width: 100%;
  margin: 0px 0px;
  padding: 0px 10px;
  margin-right: 40vw;
  border: none;
}

.newsfeed-swiper-wrapper {
  width: 100%;
  padding-right: 0vw;
}

/* Newsfeed Step */
.newsfeed-step {
  padding: 40px 10px;
}

.mobile-hiddden {
  display: none;
}

.newsfeed h3 {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 1.2rem;
}

.newsfeed p {
  font-size: 0.8rem;
  margin: 0px;
}

blockquote {
  margin: 0px;
}

.newsfeed a {
  text-decoration: none;
  font-size: 0.8rem;
}

/* 
Pricing Page on Mobile */

.pricing {
  padding: 0px;
  padding-top: 20px;
}

.pricing .popularity {
  font-size: 0.5rem;
  letter-spacing: 0.2rem;
  display: block;
  /* padding-top: 20px; */
  margin-bottom: 20px;
  height: 55px;
}

.pricing .popularity.outlined {
  height: 35px;
  width: auto;
}

.pricing .popularity.no-outline {
  height: 35px;
}

.row > .pricing-columns {
  padding-right: 1px;
  padding-left: 1px;
}

.pricing-columns h3 {
  font-size: 0.6rem;
}

.material-icon.sizing {
  font-size: 15px;
}

.pricing.popular.gold {
  border: 2px solid var(--gold-color);
}

.pricing.popular.silver {
  border: 2px solid var(--white-color);
}

.pricing.popular.family {
  border: 2px solid var(--white-color);
}

.pricing .btn-white {
  font-size: 0.5rem;
  padding: 2px 5px;
}

.pricing .btn-blue {
  font-size: 0.5rem;
  padding: 2px 5px;
}

.li-features {
  font-size: 0.45rem;
}

.pricing .price-cta .price {
  font-size: 0.7rem;
}

.price-cta .subtext {
  margin-top: -10px;
  margin-bottom: 19px;
}

.only-monthly {
  margin-bottom: 26px;
}

.tutorial-top-container {
  width: 100%;
}

.tutorial-container h2 {
  margin: 10px 0px;
}

}