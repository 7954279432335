.solutions-column-check {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    padding: auto;
}

.solutions-a {
    text-decoration: none;
    color: var(--silver-color);
}

.solutions-b {
    text-decoration: none;
    color: var(--gold-color);
}

.solutions-a:hover{
    text-decoration: none;
    color: var(--white-color);
}

.solutions-b:hover{
    text-decoration: none;
    color: var(--gold-color-hover);
}
