.social a {
    margin-left: 10px;
}

.contact-subtitle {
    color: var(--white-color);
    font-size: 1.2rem;
    margin-left: 10px;
}

.section-heading{margin:0px !important}
.col-md-5{align-items:normal !important}
.contact-list {
    border: thin solid var(--silver-color);
    padding: 10px;
    background-color: var(--white-color);
    border-radius: 8px;
    margin-top: 20px;
}

.list-icon {
    font-size: 24px;
    color: var(--white-color);
    font-weight: 900;
    background-color: var(--tertiary-color);
    padding: 5px;
    border-radius: 6px;
    display: block;
    height: 40px;
}

@media (max-width: 768px) {
    .contact-form-container form {
        margin: 0px 0px 75px 0px !important;
    }
}

textarea {
    color: var(--tertiary-color);
}

.ErrorMessage {
    color: var(--error-color);
    background-color: var(--error-background-color);
    border: 1px solid var(--bg-color);
    font-weight: 600;
    margin-bottom: 10px;
    border-radius: 3px;
    padding: 5px;
    text-align: left;
    display:block !important;
}

.SuccessMessage {
    color: var(--green-color);
    background-color: var(--blue-green-color-opacity-25);
    border: 1px solid var(--blue-green-color-opacity-50);
    font-weight: 600;
    margin-bottom: 10px;
    border-radius: 3px;
    padding: 5px;
    text-align: left;
    display: block !important;
}